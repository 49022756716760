import * as i0 from "@angular/core";
export class LocalStorage {
    constructor() {
        if (this.localStorageAvailable()) {
            this.storage = localStorage;
        }
        else {
            this.storage = null;
        }
    }
    /**
     * Retrieve specified item from local storage.
     */
    get(key, defaultValue) {
        if (!this.storage)
            return defaultValue;
        let value;
        try {
            value = JSON.parse(this.storage.getItem(key));
        }
        catch (e) {
            //
        }
        return value == null ? defaultValue : value;
    }
    /**
     * Store specified item in local storage.
     */
    set(key, value) {
        if (!this.storage)
            return null;
        value = JSON.stringify(value);
        this.storage.setItem(key, value);
    }
    /**
     * Remove specified item from local storage.
     */
    remove(key) {
        if (!this.storage)
            return null;
        this.storage.removeItem(key);
    }
    /**
     * Check if local storage is available.
     */
    localStorageAvailable() {
        const test = 'test';
        try {
            localStorage.setItem(test, test);
            localStorage.removeItem(test);
            return true;
        }
        catch (e) {
            return false;
        }
    }
}
LocalStorage.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalStorage_Factory() { return new LocalStorage(); }, token: LocalStorage, providedIn: "root" });
