import { HomepageAppearancePanelComponent } from './admin/appearance/homepage-appearance-panel/homepage-appearance-panel.component';
import { AppConfig } from '@common/core/config/app-config';

export const BEDRIVE_CONFIG: AppConfig = {
    assetsPrefix: 'client',
    navbar: {
        defaultPosition: 'drive-navbar',
        dropdownItems: [
            {route: '/drive', name: 'My Drive', icon: 'network-drive-custom'},
        ]
    },
    auth: {
        redirectUri: '/drive',
        adminRedirectUri: '/drive',
        color: 'primary',
    },
    accountSettings: {
        hideNavbar: false,
    },
    customPages: {
        hideNavbar: false,
    },
    demo: {
        email: null,
    },
    admin: {
        ads: [
            {
                slot: 'ads.file-preview',
                description: 'This ad will appear on shared file preview page.',
            },
            {
                slot: 'ads.drive',
                description: 'This ad will appear on user drive page.',
            },
            {
                slot: 'ads.landing.top',
                description: 'This ad will appear at the top of the landing page.',
            },
        ],
        appearance: {
            defaultRoute: 'drive',
            navigationRoutes: [
                's',
                'drive',
            ],
            menus: {
                positions: [
                    'drive-navbar',
                    'drive-sidebar',
                    'homepage-navbar',
                    'admin-navbar',
                    'custom-page-navbar',
                ],
                availableRoutes: [
                    'drive/shares',
                    'drive/recent',
                    'drive/starred',
                    'drive/trash',
                ]
            },
            sections: [
                {
                    name: 'landing page',
                    component: HomepageAppearancePanelComponent,
                    position: 1,
                    route: '/',
                }
            ]
        }
    },
    translations: {
        uploads_disk_driver_description: 'Where drive file uploads should be stored.',
    }
};
