import { Observable, of } from 'rxjs';
import { share } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpErrorHandler } from './errors/http-error-handler.service';
import { AppHttpClient } from './app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./errors/http-error-handler.service";
export class HttpCacheClient extends AppHttpClient {
    /**
     * HttpClient Constructor.
     */
    constructor(http, errorHandler) {
        super(http, errorHandler);
        this.http = http;
        this.errorHandler = errorHandler;
        /**
         * Http cache store.
         */
        this.cache = {};
    }
    /**
     * Make new GET request or returned its cached response.
     */
    getWithCache(url, params = {}) {
        const key = this.makeCacheKey(url, params);
        // this request is in progress and not yet resolved,
        // we can return same observable for all callers
        if (this.cache[key] instanceof Observable) {
            return this.cache[key];
            // this request is completed and cached, we can return cached response
        }
        else if (this.cache[key]) {
            return of(this.cache[key]);
            // this request was not made yet, so we make a new one
        }
        else {
            const request = this.get(url, params).pipe(share());
            request.subscribe(data => this.cache[key] = data, () => { });
            return this.cache[key] = request;
        }
    }
    /**
     * Clear cache on POST requests.
     */
    post(url, params, headers) {
        this.clearCache();
        return super.post(url, params);
    }
    /**
     * Clear cache on PUT requests.
     */
    put(url, params = {}, headers) {
        this.clearCache();
        return super.put(url, params);
    }
    /**
     * Clear cache on DELETE requests.
     */
    delete(url, params = {}, headers) {
        this.clearCache();
        return super.delete(url, params);
    }
    /**
     * Clear http cache for this service.
     */
    clearCache() {
        this.cache = {};
    }
    /**
     * Create unique cache key for specified http request.
     */
    makeCacheKey(url, params) {
        return url + JSON.stringify(params);
    }
}
HttpCacheClient.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpCacheClient_Factory() { return new HttpCacheClient(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.HttpErrorHandler)); }, token: HttpCacheClient, providedIn: "root" });
