import { Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { GuestGuard } from '@common/guards/guest-guard.service';
import { ContactComponent } from '@common/contact/contact.component';
import { NOT_FOUND_ROUTES } from '@common/core/pages/not-found-routes';
const ɵ0 = () => import("./admin/app-admin.module.ngfactory").then(m => m.AppAdminModuleNgFactory), ɵ1 = () => import("./drive/drive.module.ngfactory").then(m => m.DriveModuleNgFactory), ɵ2 = () => import("./../common/billing/billing.module.ngfactory").then(m => m.BillingModuleNgFactory);
const routes = [
    { path: '', pathMatch: 'full', component: HomepageComponent, canActivate: [GuestGuard] },
    { path: 'admin', loadChildren: ɵ0 },
    { path: 'drive', loadChildren: ɵ1 },
    { path: 'billing', loadChildren: ɵ2 },
    { path: 'contact', component: ContactComponent },
    ...NOT_FOUND_ROUTES,
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
