import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { MetaTagsService } from './meta-tags.service';
import * as i0 from "@angular/core";
import * as i1 from "./meta-tags.service";
export class MetaTagsInterceptor {
    constructor(metaTags) {
        this.metaTags = metaTags;
    }
    intercept(req, next) {
        return next.handle(req).pipe(tap(e => {
            if (e instanceof HttpResponse && e.body && e.body.seo) {
                this.metaTags.latestMetaTags$.next(e.body.seo);
            }
            return e;
        }));
    }
}
MetaTagsInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MetaTagsInterceptor_Factory() { return new MetaTagsInterceptor(i0.ɵɵinject(i1.MetaTagsService)); }, token: MetaTagsInterceptor, providedIn: "root" });
