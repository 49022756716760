import { MatDialog } from '@angular/material/dialog';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
export class Modal {
    constructor(dialog) {
        this.dialog = dialog;
    }
    open(component, data = {}, config = {}) {
        if (!data)
            data = {};
        if (typeof config === 'string')
            config = { panelClass: config };
        if (!Array.isArray(config.panelClass))
            config.panelClass = [config.panelClass];
        config.panelClass.push('be-modal');
        return this.dialog.open(component, Object.assign({}, config, { data }));
    }
    show(component, data = {}) {
        return this.open(component, data);
    }
    anyDialogOpen() {
        return this.dialog.openDialogs.length > 0;
    }
    closeAll() {
        this.dialog.closeAll();
    }
}
Modal.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Modal_Factory() { return new Modal(i0.ɵɵinject(i1.MatDialog)); }, token: Modal, providedIn: "root" });
