import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/layout";
export class BreakpointsService {
    constructor(breakpointObserver) {
        this.breakpointObserver = breakpointObserver;
        this.isMobile$ = new BehaviorSubject(false);
        this.isTablet$ = new BehaviorSubject(false);
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
            this.isMobile$.next(result.matches);
        });
        this.breakpointObserver.observe(Breakpoints.Tablet).subscribe(result => {
            this.isTablet$.next(result.matches);
        });
    }
    observe(value) {
        return this.breakpointObserver.observe(value);
    }
}
BreakpointsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BreakpointsService_Factory() { return new BreakpointsService(i0.ɵɵinject(i1.BreakpointObserver)); }, token: BreakpointsService, providedIn: "root" });
