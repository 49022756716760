import { throwError } from 'rxjs';
import { Translations } from '../../translations/translations.service';
import * as i0 from "@angular/core";
import * as i1 from "../../translations/translations.service";
export class HttpErrorHandler {
    constructor(i18n) {
        this.i18n = i18n;
    }
    /**
     * Handle http request error.
     */
    handle(response, uri) {
        const body = this.parseJson(response.error);
        const error = {
            uri,
            messages: body.messages,
            type: 'http',
            status: response.status,
            originalError: new Error(response.message)
        };
        if (response.status === 403 || response.status === 401) {
            this.handle403Error(body);
        }
        return throwError(error);
    }
    /**
     * Parse JSON without throwing errors.
     */
    parseJson(json) {
        let original;
        if (typeof json !== 'string') {
            original = json;
        }
        else {
            try {
                original = JSON.parse(json);
            }
            catch (e) {
                original = this.getEmptyErrorBody();
            }
        }
        if (!original || !original.messages) {
            return this.getEmptyErrorBody();
        }
        Object.keys(original.messages).forEach(key => {
            const message = original.messages[key];
            original.messages[key] = Array.isArray(message) ? message[0] : message;
        });
        return original;
    }
    getEmptyErrorBody() {
        return { status: 'error', messages: {} };
    }
}
HttpErrorHandler.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpErrorHandler_Factory() { return new HttpErrorHandler(i0.ɵɵinject(i1.Translations)); }, token: HttpErrorHandler, providedIn: "root" });
