import { Settings } from '../config/settings.service';
import { AppHttpClient } from '../http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../http/app-http-client.service";
import * as i2 from "../config/settings.service";
export class Localizations {
    constructor(http, settings) {
        this.http = http;
        this.settings = settings;
    }
    /**
     * Get all available  localizations.
     */
    all() {
        return this.http.get('localizations');
    }
    /**
     * Get localization by specified name.
     */
    get(name) {
        return this.http.get('localizations/' + name);
    }
    /**
     * Create new localization.
     */
    create(params) {
        return this.http.post('localizations', params);
    }
    /**
     * Update specified localization.
     */
    update(id, params) {
        return this.http.put('localizations/' + id, params);
    }
    /**
     * Delete specified localization.
     */
    delete(id) {
        return this.http.delete('localizations/' + id);
    }
    /**
     * Set specified localization as default for new users.
     */
    setDefault(name) {
        const params = { server: { app_locale: name } };
        return this.settings.save(params);
    }
}
Localizations.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Localizations_Factory() { return new Localizations(i0.ɵɵinject(i1.AppHttpClient), i0.ɵɵinject(i2.Settings)); }, token: Localizations, providedIn: "root" });
