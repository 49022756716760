import * as Dot from 'dot-object';
import merge from 'deepmerge';
import { objToFormData } from '../utils/obj-to-form-data';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class Settings {
    constructor() {
        this.config$ = new BehaviorSubject({});
    }
    setMultiple(settings) {
        if (!settings)
            return;
        const newConfig = Object.assign({}, this.config$.value);
        Object.entries(settings).forEach(([key, value]) => {
            if (value === '0' || value === '1') {
                value = parseInt(value);
            }
            Dot.set(key, value, newConfig);
        });
        this.config$.next(newConfig);
    }
    merge(config) {
        this.config$.next(merge(this.config$.value, config));
    }
    set(name, value) {
        const newConfig = Object.assign({}, this.config$.value);
        Dot.set(name, value, newConfig);
        this.config$.next(newConfig);
    }
    get(name, defaultValue = null) {
        const value = Dot.pick(name, this.config$.value);
        if (value == null) {
            return defaultValue;
        }
        else {
            return value;
        }
    }
    getFlat(name) {
        return Dot.dot(name ? this.config$.value[name] : this.config$.value);
    }
    all$() {
        return this.config$.asObservable();
    }
    has(name) {
        return !!Dot.pick(name, this.config$.value);
    }
    /**
     * Get a json setting by key and decode it.
     */
    getJson(name, defaultValue = null) {
        const value = this.get(name, defaultValue);
        if (typeof value !== 'string')
            return value;
        return JSON.parse(value);
    }
    /**
     * Get base url for the app.
     */
    getBaseUrl(forceServerUrl = false) {
        // sometimes we might need to get base url supplied by backend
        // even in development environment, for example, to prevent
        // uploaded images from having proxy urls like "localhost:4200"
        if (this.has('base_url') && (this.get('vebto.environment') === 'production' || forceServerUrl)) {
            return this.get('base_url') + '/';
        }
        else if (document.querySelector('base')) {
            return document.querySelector('base')['href'];
        }
        else {
            // 'https://site.com/subdomain/index.html/" => 'https://site.com/subdomain/'
            const url = window.location.href.split('?')[0];
            return url.replace(/([^\/]+\.\w+($|\/$))/, '');
        }
    }
    /**
     * Get app's asset base url.
     */
    getAssetUrl(suffix) {
        let uri = (this.get('vebto.assetsUrl') || this.getBaseUrl());
        const prefix = this.get('vebto.assetsPrefix');
        // in production assets will be in "client" sub-folder
        if (this.get('vebto.environment') === 'production' && prefix) {
            uri += prefix + '/';
        }
        uri += 'assets/';
        if (suffix)
            uri += suffix;
        return uri;
    }
    /**
     * Save specified setting on the server.
     */
    save(settings) {
        this.setMultiple(settings.client);
        const jsonSettings = { files: settings.files };
        // need to encode settings as json to preserve
        // booleans as form data will always be a string
        // also need to encode as base64 to make sure requests
        // are not blocked when setting contains <scripts>
        jsonSettings.client = JSON.stringify(settings.client);
        jsonSettings.server = JSON.stringify(settings.server);
        const data = objToFormData(jsonSettings);
        return this.http.post('settings', data);
    }
    /**
     * Check if any social login is enabled.
     */
    anySocialLoginEnabled() {
        const names = ['facebook', 'google', 'twitter'];
        return names.filter(name => this.get('social.' + name + '.enable')).length > -1;
    }
    /**
     * Set HttpClient instance.
     */
    setHttpClient(http) {
        this.http = http;
    }
}
Settings.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Settings_Factory() { return new Settings(); }, token: Settings, providedIn: "root" });
