import { NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CurrentUser } from './current-user';
import { Toast } from '../core/ui/toast.service';
import { Settings } from '../core/config/settings.service';
import { AppHttpClient } from '../core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../core/http/app-http-client.service";
import * as i2 from "./current-user";
import * as i3 from "@angular/router";
import * as i4 from "../core/ui/toast.service";
import * as i5 from "../core/config/settings.service";
export class AuthService {
    constructor(httpClient, currentUser, router, route, toast, zone, config) {
        this.httpClient = httpClient;
        this.currentUser = currentUser;
        this.router = router;
        this.route = route;
        this.toast = toast;
        this.zone = zone;
        this.config = config;
    }
    login(credentials) {
        return this.httpClient.post('auth/login', credentials);
    }
    register(credentials) {
        return this.httpClient.post('auth/register', credentials);
    }
    logOut() {
        this.httpClient.post('auth/logout').subscribe(() => {
            this.currentUser.assignCurrent();
            this.router.navigate(['/login']);
        });
    }
    sendPasswordResetLink(credentials) {
        return this.httpClient.post('auth/password/email', credentials);
    }
    resetPassword(credentials) {
        return this.httpClient.post('auth/password/reset', credentials);
    }
    resendEmailConfirmation(email) {
        return this.httpClient.post('auth/resend-confirmation-email', { email });
    }
    /**
     * Get URI user should be redirect to after login.
     */
    getRedirectUri() {
        if (this.currentUser.redirectUri) {
            const redirectUri = this.currentUser.redirectUri;
            this.currentUser.redirectUri = null;
            return redirectUri;
        }
        else if (this.currentUser.isAdmin()) {
            return this.config.get('vebto.auth.adminRedirectUri');
        }
        else {
            return this.config.get('vebto.auth.redirectUri');
        }
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.AppHttpClient), i0.ɵɵinject(i2.CurrentUser), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i3.ActivatedRoute), i0.ɵɵinject(i4.Toast), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i5.Settings)); }, token: AuthService, providedIn: "root" });
