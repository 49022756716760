import { Routes } from '@angular/router';
import { CustomPageComponent } from './custom-page/custom-page.component';
const ɵ0 = { permissions: ['custom_pages.view'], willSetSeo: true };
const routes = [
    {
        path: 'pages/:id/:slug',
        component: CustomPageComponent,
        data: ɵ0
    }
];
export class PagesRoutingModule {
}
export class PagesModule {
}
export { ɵ0 };
