import { Router } from '@angular/router';
import { CurrentUser } from '../auth/current-user';
import * as i0 from "@angular/core";
import * as i1 from "../auth/current-user";
import * as i2 from "@angular/router";
export class AuthGuard {
    constructor(currentUser, router) {
        this.currentUser = currentUser;
        this.router = router;
    }
    canActivate(route, state) {
        return this.handle(state.url);
    }
    canActivateChild(route, state) {
        return this.handle(state.url);
    }
    canLoad(route) {
        return this.handle(route.path);
    }
    handle(url) {
        if (this.currentUser.isLoggedIn()) {
            return true;
        }
        this.currentUser.redirectUri = url;
        this.router.navigate(['/login']);
        return false;
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.CurrentUser), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
