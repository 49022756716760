import { map } from 'rxjs/operators';
import { AppHttpClient } from '../core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../core/http/app-http-client.service";
export class Users {
    constructor(http) {
        this.http = http;
    }
    get(id, params) {
        return this.http.get(`users/${id}`, params);
    }
    getAll(params = {}) {
        return this.http.get('users', params).pipe(map(response => response['pagination']['data']));
    }
    create(payload) {
        return this.http.post('users', payload);
    }
    update(id, payload) {
        return this.http.put('users/' + id, payload);
    }
    changePassword(id, payload) {
        return this.http.post('users/' + id + '/password/change', payload);
    }
    attachRoles(id, payload = {}) {
        return this.http.post('users/' + id + '/roles/attach', payload);
    }
    detachRoles(id, payload = {}) {
        return this.http.post('users/' + id + '/roles/detach', payload);
    }
    addPermissions(id, payload = {}) {
        return this.http.post('users/' + id + '/permissions/start', payload);
    }
    removePermissions(id, payload = {}) {
        return this.http.post('users/' + id + '/permissions/remove', payload);
    }
    uploadAvatar(id, files) {
        const payload = new FormData();
        payload.append('file', files[0].native);
        return this.http.post('users/' + id + '/avatar', payload);
    }
    deleteAvatar(id) {
        return this.http.delete('users/' + id + '/avatar');
    }
    delete(ids) {
        return this.http.delete(`users/${ids}`);
    }
    //
    /**
     * Sync specified user tags.
     */
    syncTags(id, payload) {
        return this.http.post('users/' + id + '/tags/sync', payload);
    }
    /**
     * Update details about user.
     */
    updateDetails(id, payload) {
        return this.http.put('users/' + id + '/details', payload);
    }
    /**
     * Add secondary email to specified user.
     */
    addEmail(id, payload) {
        return this.http.post('users/' + id + '/emails/attach', payload);
    }
    /**
     * Remove secondary email from specified user.
     */
    removeEmail(id, payload) {
        return this.http.post('users/' + id + '/emails/detach', payload);
    }
}
Users.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Users_Factory() { return new Users(i0.ɵɵinject(i1.AppHttpClient)); }, token: Users, providedIn: "root" });
