import { makeUploadPayload } from '../core/utils/make-upload-payload';
import { AppHttpClient } from '../core/http/app-http-client.service';
import { transformAngularUploadEvent } from '@common/uploads/utils/upload-progress-event';
import { map } from 'rxjs/operators';
import { ChunkedUploadService } from '@common/uploads/chunked/chunked-upload.service';
import { Settings } from '@common/core/config/settings.service';
import * as i0 from "@angular/core";
import * as i1 from "../core/http/app-http-client.service";
import * as i2 from "./chunked/chunked-upload.service";
import * as i3 from "../core/config/settings.service";
export class UploadsApiService {
    constructor(http, chunkedUpload, settings) {
        this.http = http;
        this.chunkedUpload = chunkedUpload;
        this.settings = settings;
    }
    getFileContents(file) {
        return this.http.get('uploads/' + file.id, null, { responseType: 'text' });
    }
    delete(params) {
        return this.http.delete('uploads', params);
    }
    upload(file, config = {}) {
        if (file.relativePath) {
            config.httpParams.relativePath = file.relativePath;
        }
        if (this.settings.get('uploads.chunk') && file.size > this.chunkedUpload.sliceSize) {
            return this.chunkedUpload.start(file, config.httpParams);
        }
        else {
            const now = Date.now();
            return this.http.postWithProgress(config.uri || 'uploads', makeUploadPayload(file.native, config.httpParams)).pipe(map((e) => transformAngularUploadEvent(e, now)));
        }
    }
}
UploadsApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UploadsApiService_Factory() { return new UploadsApiService(i0.ɵɵinject(i1.AppHttpClient), i0.ɵɵinject(i2.ChunkedUploadService), i0.ɵɵinject(i3.Settings)); }, token: UploadsApiService, providedIn: "root" });
