import { EventEmitter } from '@angular/core';
import { Localization } from '../types/models/Localization';
import { Settings } from '../config/settings.service';
import * as i0 from "@angular/core";
import * as i1 from "../config/settings.service";
export class Translations {
    constructor(settings) {
        this.settings = settings;
        /**
         * Fired when active localization changes.
         */
        this.localizationChange = new EventEmitter;
        /**
         * Currently active localization.
         */
        this.localization = { model: new Localization(), name: '', lines: {} };
    }
    /**
     * Translate specified key.
     */
    t(transKey, values) {
        if (!transKey)
            return '';
        if (!this.translationsEnabled()) {
            return this.replacePlaceholders(transKey, values);
        }
        const translation = this.localization.lines[transKey.toLowerCase().trim()] || transKey;
        return this.replacePlaceholders(translation, values);
    }
    replacePlaceholders(message, values) {
        if (!values)
            return message;
        const keys = Object.keys(values);
        keys.forEach(key => {
            message = message.replace(':' + key, values[key]);
        });
        return message;
    }
    /**
     * Get currently active localization.
     */
    getActive() {
        return this.localization;
    }
    /**
     * Set active localization.
     */
    setLocalization(localization) {
        if (!localization || !localization.lines || !localization.model)
            return;
        if (this.localization.model.name === localization.model.name)
            return;
        localization.lines = this.objectKeysToLowerCase(localization.lines);
        this.localization = localization;
        this.localizationChange.emit();
    }
    objectKeysToLowerCase(object) {
        const newObject = {};
        Object.keys(object).forEach(key => {
            newObject[key.toLowerCase()] = object[key];
        });
        return newObject;
    }
    /**
     * Check if i18n functionality is enabled.
     */
    translationsEnabled() {
        return this.settings.get('i18n.enable');
    }
}
Translations.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Translations_Factory() { return new Translations(i0.ɵɵinject(i1.Settings)); }, token: Translations, providedIn: "root" });
