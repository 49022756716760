import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorHandler } from '@common/core/http/errors/http-error-handler.service';
import { Translations } from '@common/core/translations/translations.service';
import { CurrentUser } from '@common/auth/current-user';
import { Toast } from '@common/core/ui/toast.service';
import { Settings } from '@common/core/config/settings.service';
import { Modal } from '@common/core/ui/dialogs/modal.service';
import { CommonMessages } from '@common/core/ui/common-messages.enum';
import * as i0 from "@angular/core";
import * as i1 from "../../translations/translations.service";
import * as i2 from "../../../auth/current-user";
import * as i3 from "@angular/router";
import * as i4 from "../../ui/toast.service";
import * as i5 from "../../config/settings.service";
import * as i6 from "../../ui/dialogs/modal.service";
export class BackendHttpErrorHandler extends HttpErrorHandler {
    constructor(i18n, currentUser, router, toast, zone, settings, modal) {
        super(i18n);
        this.i18n = i18n;
        this.currentUser = currentUser;
        this.router = router;
        this.toast = toast;
        this.zone = zone;
        this.settings = settings;
        this.modal = modal;
    }
    /**
     * Redirect user to login page or show toast informing
     * user that he does not have required permissions.
     */
    handle403Error(response) {
        // if user doesn't have access, navigate to login page
        if (this.currentUser.isLoggedIn()) {
            this.showToast(response);
        }
        else {
            this.router.navigate(['/login']);
        }
    }
    showToast(response) {
        const config = {};
        if (this.settings.get('billing.enable') && response.showUpgradeButton) {
            config.action = 'Upgrade';
            config.duration = 15000;
        }
        this.toast.open(response.messages.general || CommonMessages.NoPermissions, config)
            .onAction()
            .subscribe(() => {
            this.router.navigateByUrl('/billing/upgrade');
            this.modal.closeAll();
        });
    }
}
BackendHttpErrorHandler.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BackendHttpErrorHandler_Factory() { return new BackendHttpErrorHandler(i0.ɵɵinject(i1.Translations), i0.ɵɵinject(i2.CurrentUser), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.Toast), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i5.Settings), i0.ɵɵinject(i6.Modal)); }, token: BackendHttpErrorHandler, providedIn: "root" });
