import { HttpCacheClient } from '../../http/http-cache-client';
import * as i0 from "@angular/core";
import * as i1 from "../../http/http-cache-client";
export const CUSTOM_PAGE_URI = 'page';
export class Pages {
    constructor(http) {
        this.http = http;
    }
    getAll(params = {}) {
        return this.http.getWithCache(`${CUSTOM_PAGE_URI}`, params);
    }
    get(id) {
        return this.http.getWithCache(`${CUSTOM_PAGE_URI}/${id}`);
    }
    create(params) {
        return this.http.post(`${CUSTOM_PAGE_URI}`, params);
    }
    update(id, params) {
        return this.http.put(`${CUSTOM_PAGE_URI}/${id}`, params);
    }
    delete(ids) {
        return this.http.delete(`${CUSTOM_PAGE_URI}/${ids}`);
    }
}
Pages.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Pages_Factory() { return new Pages(i0.ɵɵinject(i1.HttpCacheClient)); }, token: Pages, providedIn: "root" });
