import { HttpCacheClient } from '../http/http-cache-client';
import * as i0 from "@angular/core";
import * as i1 from "../http/http-cache-client";
export class ValueLists {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    get(names, params = {}) {
        return this.httpClient.getWithCache('value-lists/' + names.join(','), params);
    }
}
ValueLists.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ValueLists_Factory() { return new ValueLists(i0.ɵɵinject(i1.HttpCacheClient)); }, token: ValueLists, providedIn: "root" });
