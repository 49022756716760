import { HttpClient, HttpEventType, HttpParams, HttpRequest } from '@angular/common/http';
import { catchError, filter } from 'rxjs/operators';
import { HttpErrorHandler } from '@common/core/http/errors/http-error-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./errors/http-error-handler.service";
export class AppHttpClient {
    constructor(httpClient, errorHandler) {
        this.httpClient = httpClient;
        this.errorHandler = errorHandler;
    }
    get(uri, params = {}, options = {}) {
        const httpParams = this.generateHttpParams(params);
        return this.httpClient.get(this.prefixUri(uri), Object.assign({ params: httpParams }, options))
            .pipe(catchError(err => this.errorHandler.handle(err, uri)));
    }
    post(uri, params = null) {
        return this.httpClient.post(this.prefixUri(uri), params)
            .pipe(catchError(err => this.errorHandler.handle(err, uri)));
    }
    put(uri, params = {}) {
        params = this.spoofHttpMethod(params, 'PUT');
        return this.httpClient.post(this.prefixUri(uri), params)
            .pipe(catchError(err => this.errorHandler.handle(err, uri)));
    }
    delete(uri, params = {}) {
        params = this.spoofHttpMethod(params, 'DELETE');
        return this.httpClient.post(this.prefixUri(uri), params)
            .pipe(catchError(err => this.errorHandler.handle(err, uri)));
    }
    postWithProgress(uri, params) {
        const req = new HttpRequest('POST', this.prefixUri(uri), params, {
            reportProgress: true
        });
        return this.httpClient.request(req).pipe(catchError(err => this.errorHandler.handle(err, uri)), filter(e => [HttpEventType.Sent, HttpEventType.UploadProgress, HttpEventType.Response].includes(e.type)));
    }
    /**
     * Prefix specified uri with backend API prefix.
     */
    prefixUri(uri) {
        if (uri.indexOf('://') > -1)
            return uri;
        return AppHttpClient.prefix + '/' + uri;
    }
    /**
     * Generate http params for GET request.
     */
    generateHttpParams(params) {
        let httpParams = new HttpParams();
        if (!params)
            return httpParams;
        Object.keys(params).forEach(key => {
            let value = params[key];
            if (value == null)
                value = '';
            httpParams = httpParams.append(key, value);
        });
        return httpParams;
    }
    /**
     * Spoof http method by adding '_method' to request params.
     */
    spoofHttpMethod(params, method) {
        if (params instanceof FormData) {
            params.append('_method', method);
        }
        else {
            params['_method'] = method;
        }
        return params;
    }
}
AppHttpClient.prefix = 'secure';
AppHttpClient.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppHttpClient_Factory() { return new AppHttpClient(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.HttpErrorHandler)); }, token: AppHttpClient, providedIn: "root" });
