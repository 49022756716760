import { MatSnackBar } from '@angular/material/snack-bar';
import { Settings } from '../config/settings.service';
import { Translations } from '../translations/translations.service';
import { HttpErrors } from '../http/errors/http-errors.enum';
import * as i0 from "@angular/core";
import * as i1 from "../config/settings.service";
import * as i2 from "../translations/translations.service";
import * as i3 from "@angular/material/snack-bar";
export class Toast {
    constructor(settings, i18n, snackbar) {
        this.settings = settings;
        this.i18n = i18n;
        this.snackbar = snackbar;
    }
    open(message, config = {}) {
        if (!config.duration && config.duration !== 0) {
            config.duration = this.settings.get('toast.default_timeout', 3000);
        }
        if (!message) {
            message = HttpErrors.Default;
        }
        const translatedMsg = typeof message === 'string' ?
            this.i18n.t(message) :
            this.i18n.t(message.message, message.replacements);
        return this.snackbar.open(this.i18n.t(translatedMsg), this.i18n.t(config.action), { duration: config.duration });
    }
    openComponent(component, config) {
        return this.snackbar.openFromComponent(component, config);
    }
}
Toast.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Toast_Factory() { return new Toast(i0.ɵɵinject(i1.Settings), i0.ɵɵinject(i2.Translations), i0.ɵɵinject(i3.MatSnackBar)); }, token: Toast, providedIn: "root" });
