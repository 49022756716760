var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Settings } from '../core/config/settings.service';
import { AppHttpClient } from '../core/http/app-http-client.service';
import { Toast } from '../core/ui/toast.service';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RecaptchaService } from '../core/services/recaptcha.service';
export class ContactComponent {
    constructor(settings, http, toast, router, recaptcha) {
        this.settings = settings;
        this.http = http;
        this.toast = toast;
        this.router = router;
        this.recaptcha = recaptcha;
        this.loading$ = new BehaviorSubject(false);
        this.form = new FormGroup({
            name: new FormControl(),
            email: new FormControl(),
            message: new FormControl()
        });
        this.errors$ = new BehaviorSubject({});
    }
    ngOnInit() {
        if (this.recaptcha.enabledFor('contact')) {
            this.recaptcha.load();
        }
    }
    submitMessage() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading$.next(true);
            if (this.recaptcha.enabledFor('contact') && !(yield this.recaptcha.verify('contact'))) {
                return this.toast.open('Could not verify you are human.');
            }
            this.http.post('contact-page', this.form.value)
                .pipe(finalize(() => {
                this.loading$.next(false);
            })).subscribe(() => {
                this.errors$.next({});
                this.toast.open('Your message has been submitted.');
                this.router.navigate(['/']);
            }, err => this.errors$.next(err.messages));
        });
    }
}
