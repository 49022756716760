import { Settings } from '../config/settings.service';
import * as i0 from "@angular/core";
import * as i1 from "../config/settings.service";
export class LazyLoaderService {
    constructor(config) {
        this.config = config;
        this.loadedAssets = {};
    }
    /**
     * Load js or css asset and return promise resolved on load event.
     */
    loadAsset(url, params = { type: 'js' }) {
        // script is already loaded, return resolved promise
        if (this.loadedAssets[url] === 'loaded' && !params.force) {
            return new Promise((resolve) => resolve());
            // script has never been loaded before, load it, return promise and resolve on script load event
        }
        else if (!this.loadedAssets[url] || (params.force && this.loadedAssets[url] === 'loaded')) {
            this.loadedAssets[url] = new Promise((resolve, reject) => {
                const finalUrl = url.indexOf('//') > -1 ? url : this.config.getAssetUrl() + url;
                const finalId = params.id || url.split('/').pop();
                if (params.type === 'css') {
                    this.loadStyleAsset(finalUrl, finalId, resolve);
                }
                else {
                    this.loadScriptAsset(finalUrl, finalId, resolve);
                }
            });
            return this.loadedAssets[url];
            // script is still loading, return existing promise
        }
        else {
            return this.loadedAssets[url];
        }
    }
    loadStyleAsset(url, id, resolve) {
        const style = document.createElement('link');
        style.rel = 'stylesheet';
        style.id = id || url.split('/').pop();
        style.href = url.indexOf('//') > -1 ? url : this.config.getAssetUrl() + url;
        style.onload = () => {
            this.loadedAssets[url] = 'loaded';
            resolve();
        };
        document.head.appendChild(style);
    }
    loadScriptAsset(url, id, resolve) {
        const s = document.createElement('script');
        s.async = true;
        s.id = id || url.split('/').pop();
        s.src = url.indexOf('//') > -1 ? url : this.config.getAssetUrl() + url;
        s.onload = () => {
            this.loadedAssets[url] = 'loaded';
            resolve();
        };
        document.body.appendChild(s);
    }
}
LazyLoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LazyLoaderService_Factory() { return new LazyLoaderService(i0.ɵɵinject(i1.Settings)); }, token: LazyLoaderService, providedIn: "root" });
