import { makeUploadPayload } from '@common/core/utils/make-upload-payload';
import { from, of } from 'rxjs';
import { transformAngularUploadEvent, UploadEventTypes } from '@common/uploads/utils/upload-progress-event';
import { filter, map, mergeMap, startWith, switchMap, tap } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { AppHttpClient } from '@common/core/http/app-http-client.service';
import { Settings } from '@common/core/config/settings.service';
import { getUploadProgress } from '@common/uploads/utils/get-upload-progress';
import * as i0 from "@angular/core";
import * as i1 from "../../core/http/app-http-client.service";
import * as i2 from "../../core/config/settings.service";
export class ChunkedUploadService {
    constructor(http, settings) {
        this.http = http;
        this.settings = settings;
        this.uploadedChunksCount = 0;
        this.sliceSize = this.settings.get('uploads.chunk_size', 5242880);
    }
    start(file, httpParams = {}) {
        return this.reset(file, httpParams).pipe(switchMap(response => {
            const now = Date.now();
            const initiallyLoaded = response.uploadedChunks.reduce((a, b) => a + b.size, 0);
            let totalLoaded = initiallyLoaded;
            // all chunks for this upload session were already uploaded
            if (response.fileEntry) {
                return of(this.transformUploadSessionResponse(response));
            }
            return from(this.chunks)
                .pipe(mergeMap(chunk => {
                let loadedLastRequest = 0;
                return this.http.postWithProgress('uploads/sessions/chunks', chunk)
                    .pipe(map((e) => {
                    if (e.type === HttpEventType.UploadProgress) {
                        e.initiallyLoaded = initiallyLoaded;
                        e.total = file.size;
                        // need to sum only the amount of bytes loaded this request and not total bytes loaded
                        const loadedThisRequest = e.loaded - loadedLastRequest;
                        loadedLastRequest = e.loaded;
                        e.loaded = totalLoaded += loadedThisRequest;
                        // sometimes bytes might get out of sync for last chunk
                        if (e.loaded > e.total) {
                            e.loaded = e.total - 1;
                        }
                    }
                    return e;
                }));
            }, 3), map((e) => transformAngularUploadEvent(e, now)), tap(e => {
                if (e.name === UploadEventTypes.COMPLETED) {
                    this.uploadedChunksCount++;
                }
            }), filter(e => {
                // only need to let through all upload progress events and upload completed event for the very last chunk
                return e.name === UploadEventTypes.PROGRESS || this.isLastChunkCompletedEvent(e);
            }), switchMap(e => {
                if (this.isLastChunkCompletedEvent(e)) {
                    return this.fetchUploadSession().pipe(map(r => this.transformUploadSessionResponse(r)));
                }
                else {
                    return of(e);
                }
            }), startWith(this.getInitialUploadProgressEvent()));
        }));
    }
    getInitialUploadProgressEvent() {
        const completedBytes = this.uploadedChunksCount * this.sliceSize;
        return {
            type: HttpEventType.UploadProgress,
            name: UploadEventTypes.PROGRESS,
            totalBytes: this.file.size,
            completedBytes,
            progress: getUploadProgress(completedBytes, this.file.size),
            speed: null,
            eta: null,
        };
    }
    isLastChunkCompletedEvent(e) {
        return e.name === UploadEventTypes.COMPLETED && this.uploadedChunksCount === this.totalChunks;
    }
    transformUploadSessionResponse(response) {
        return { type: HttpEventType.Response, name: UploadEventTypes.COMPLETED, body: response };
    }
    reset(file, httpParams) {
        this.file = file;
        this.httParams = httpParams;
        this.chunks = [];
        this.uploadedChunksCount = 0;
        this.totalChunks = Math.ceil(this.file.size / this.sliceSize);
        this.generateFingerprint();
        this.generateHttpParams();
        return this.loadExistingChunks()
            .pipe(tap(response => {
            this.uploadedChunksCount = response.uploadedChunks.length;
            this.generateChunks(response.uploadedChunks);
        }));
    }
    loadExistingChunks() {
        if (this.settings.get('uploads.resume')) {
            return this.fetchUploadSession();
        }
        else {
            return of({ uploadedChunks: [], fileEntry: null });
        }
    }
    fetchUploadSession() {
        return this.http.post('uploads/sessions/load', this.httParams);
    }
    generateChunks(existingChunks = []) {
        let chunkNumber = 0;
        for (let start = 0; start < this.file.size; start += this.sliceSize) {
            // if chunk already uploaded, continue
            if (!existingChunks.find(c => c.number === chunkNumber)) {
                const end = start + this.sliceSize;
                const chunkHttpParams = Object.assign({}, this.httParams, { _chunkStart: start, _chunkEnd: end, _chunkNumber: chunkNumber });
                this.chunks.push(makeUploadPayload(this.file.native.slice(start, end), chunkHttpParams));
            }
            chunkNumber++;
        }
    }
    generateFingerprint() {
        this.fingerprint = btoa([
            'be-upload',
            this.file.name,
            this.file.mime,
            this.file.size,
            this.file.lastModified,
            this.file.relativePath,
        ].join('|'));
    }
    generateHttpParams() {
        this.httParams = Object.assign({}, this.httParams, { _chunkCount: this.totalChunks, _originalFileName: this.file.name, _originalFileSize: this.file.size, _fingerprint: this.fingerprint });
    }
}
ChunkedUploadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChunkedUploadService_Factory() { return new ChunkedUploadService(i0.ɵɵinject(i1.AppHttpClient), i0.ɵɵinject(i2.Settings)); }, token: ChunkedUploadService, providedIn: "root" });
